<template>
    <v-row>         
        <v-col>
            <v-card
                class="pa-0 mx-auto"
                width="500"
                
            >
                    <v-card-title
                        class="text-subtitle-2 pa-2 white--text d-flex justify-center primary"
                    >
                        <v-spacer></v-spacer>
                        <span>Print / View Reports</span>
                        <v-spacer></v-spacer>                   
                    </v-card-title>
                    <v-card-text
                        style="height: 60vh; overflow-y: auto;"
                    >
                        <v-list two-line>
                            <v-list-item-group
                                active-class="primary--text"
                                v-model="selectedReport"
                            >
                                <template v-for="(item, index) in items">                                   
                                    <v-list-item 
                                        :key="item.title"
                                        @click="displayReport(item.report)"
                                    >
                                       <template v-slot:default="{ active }">
                                           <v-list-item-content>
                                               <v-list-item-title v-text="item.title"></v-list-item-title>
                                               <v-list-item-subtitle
                                                    class="text--primary"
                                                    v-text="item.subtitle"
                                               ></v-list-item-subtitle>
                                           </v-list-item-content>
                                           <v-list-item-action>
                                               <v-icon
                                                    v-if="!active"
                                                    color="grey lighten-1"
                                               >
                                                   mdi-printer-search
                                               </v-icon>
                                               <v-icon
                                                    v-else
                                                    color="primary"
                                               >
                                                   mdi-printer-search
                                               </v-icon>
                                           </v-list-item-action>
                                       </template>
                                   </v-list-item>

                                   <v-divider
                                        v-if="index < items.length -1"
                                        :key="index"
                                   ></v-divider>
                                </template>
                            </v-list-item-group>
                        </v-list>
                    </v-card-text>
                    <v-overlay
                        absolute
                        :value="overlay"
                        color="grey"
                        opacity="0.6"
                   >
                        <v-progress-circular 
                            transition="fade-transition" 
                            indeterminate 
                            size="64"
                            v-show="loading"
                            color="primary"
                        ></v-progress-circular>
                        
                        <v-expand-transition>                            
                            <v-card
                                max-width="300"
                                class="mx-auto"
                                transition="scroll-y-transition"
                                v-show="expand"
                                light
                            >
                                 
                                <v-card-title
                                    class="d-flex justify-center primary white--text"
                                >
                                    <span>Select Class</span>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text                
                                        x-small
                                        color="white"
                                        @click="close"
                                    >
                                        <v-icon>mdi-window-close</v-icon>
                                    </v-btn> 
                                </v-card-title>
                                <v-list 
                                    light
                                    v-if="displayAcademicPeriod"
                                >
                                    <v-list-item>                                        
                                        <v-autocomplete
                                            v-model="academicPeriod"
                                            :items="academicPeriods"
                                            item-text="text"
                                            item-value="value"                                                
                                            dense
                                            filled
                                            color="blue-grey lighten-2"
                                            prepend-icon="mdi-calendar"
                                            hide-details=""
                                        ></v-autocomplete>                                        
                                    </v-list-item>
                                </v-list>
                                <div style="max-height: 400px;  overflow-y: auto">    
                                    <v-list 
                                        v-if="listGroup"
                                        light
                                    >
                                        <v-list-group
                                            v-for="item in formClassesFilter"
                                            :key = "item.title"
                                            v-model="item.active"
                                            :prepend-icon="item.action"
                                            no-action
                                        >
                                            <template v-slot:activator>
                                                <v-list-item-content>
                                                    <v-list-item-title v-text="item.title"></v-list-item-title>
                                                </v-list-item-content>
                                            </template>
                                            <v-list-item
                                                v-for="subItem in item.items"
                                                :key="subItem.title"
                                                @click="setSelectedClass(subItem.title)"                                       
                                            >
                                                <v-list-item-content>
                                                    <v-list-item-title v-text="subItem.title"></v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list-group>
                                    </v-list>
                                    <v-list
                                        v-else
                                    >
                                        <v-list-item-group
                                            v-model="selectedFormLevel"
                                            color="primary"
                                        >
                                            <v-list-item
                                                v-for="(item, i) in formLevels"
                                                :key="i"
                                                @click="setSelectedFormLevel(item.value)" 
                                            >
                                                <v-list-item-icon>
                                                    <v-icon v-text="item.icon"></v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-content>
                                                    <v-list-item-title v-text="item.text"></v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list-item-group>
                                    </v-list>
                                </div>
                            </v-card> 
                        </v-expand-transition>

                         <v-expand-transition>                            
                            <v-card
                                max-width="600"
                                class="mx-auto"
                                transition="scroll-y-transition"
                                v-show="expandSelectStudent"
                                light
                            >
                                 
                                <v-card-title
                                    class="d-flex justify-center primary white--text"
                                >
                                   <span>Select Student</span>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text                
                                        x-small
                                        color="white"
                                        @click="close"
                                    >
                                        <v-icon>mdi-window-close</v-icon>
                                    </v-btn>
                                </v-card-title>
                                <v-card-text>
                                    <v-data-table
                                        :headers="headersStudents"
                                        :items="students"
                                        :search="searchStudent"
                                        :loading="loadingStudents"
                                        fixed-header
                                        height="40vh"
                                    >
                                        <template v-slot:top>
                                            <v-text-field
                                                v-model="searchStudent"
                                                label="Search Student"
                                                append-icon="mdi-magnify"
                                                single-line
                                                clearable
                                            ></v-text-field>
                                        </template>

                                        <template v-slot:[`item.actions`]="{ item }">
                                            <v-btn
                                                depressed 
                                                color="primary"
                                                @click="setSelectedStudent(item)"
                                                small
                                            >Select</v-btn>
                                        </template>
                                    </v-data-table>
                                </v-card-text>
                            </v-card>
                         </v-expand-transition>            
                   </v-overlay>    
                </v-card>
        </v-col>

        <v-dialog
            v-model="reportViewer"
            max-width="90vw"
            @click:outside="closeReport"
        >
            <v-card
               height="90vh"
               style="position:relative;" 
            >                
                <iframe 
                    ref="pdf" 
                    style="width:100%; height:100%" 
                    class="report-viewer"  
                    :src="src"
                ></iframe>              
                <v-overlay
                    absolute
                    :value="reportViewerOverlay"
                    color="white"
                    opacity="0.60"
                >
                    <v-progress-circular
                        indeterminate
                        size="64"
                        color="primary"
                    ></v-progress-circular>
                </v-overlay> 
            </v-card>
        </v-dialog> 
    </v-row>    
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
export default {
    created(){
        this.initialize()
    },
    
    data: () => ({
        selectedReport: null,
        items: [
            { 
                title: 'Report Cards by Class', 
                subtitle: '', 
                icon: 'mdi-printer-search',
                report: 'report-card-class', 
            },            
            { 
                title: 'Class Mark Sheets', 
                subtitle: '', 
                icon: 'mdi-printer-search',
                report: 'marksheet-class', 
            },            
            { 
                title: 'Class Lists', 
                subtitle: '', 
                icon: 'mdi-printer-search',
                report: 'class-list', 
            }, 
            { 
                title: 'Device / Internet Report', 
                subtitle: '', 
                icon: 'mdi-printer-search',
                report: 'device-internet', 
            },           
            { 
                title: 'Subject Choices Marksheets By Class', 
                subtitle: '', 
                icon: 'mdi-printer-search',
                report: 'marksheet-subject-choice-class', 
            },            
            { 
                title: 'Subject Choices Marksheets By Student', 
                subtitle: '', 
                icon: 'mdi-printer-search',
                report: 'marksheet-subject-choice-student', 
            },            
            { 
                title: 'NCSE Marksheets', 
                subtitle: '', 
                icon: 'mdi-printer-search',
                report: 'marksheet-ncse' 
            },            
            { 
                title: 'Registration Status Report', 
                subtitle: '', 
                icon: 'mdi-printer-search',
                report: 'registration-status' 
            },
            { 
                title: 'School Feeding Report', 
                subtitle: '', 
                icon: 'mdi-printer-search',
                report: 'school-feeding' 
            },              
            { 
                title: 'Student Contact Information', 
                subtitle: '', 
                icon: 'mdi-printer-search',
                report: 'contact-information' 
            },
             { 
                title: 'Student Ethnic Group Statistics', 
                subtitle: '', 
                icon: 'mdi-printer-search',
                report: 'ethnic-group-statistics' 
            },
            { 
                title: 'Student Religion Statistics', 
                subtitle: '', 
                icon: 'mdi-printer-search',
                report: 'religion-statistics' 
            },
            { 
                title: 'Student Age Statistics', 
                subtitle: '', 
                icon: 'mdi-printer-search',
                report: 'age-statistics' 
            },            
            { 
                title: 'Student Health Data', 
                subtitle: '', 
                icon: 'mdi-printer-search',
                report: 'health-data' 
            },            
            { 
                title: 'Foreign Students', 
                subtitle: '', 
                icon: 'mdi-printer-search',
                report: 'foreign-students' 
            },            
            { 
                title: 'ASR', 
                subtitle: '', 
                icon: 'mdi-printer-search',
                report: 'asr' 
            },            
        ],
        overlay: false,
        loading: false,
        expand: false,
        expandSelectStudent: false,
        formClasses: [],
        formClassesFilter: [],
        academicPeriod: '',        
        academicPeriods: [],        
        reportViewer: false,
        reportViewerOverlay: false,
        src: null,
        reportSelected: null,
        formLevelFilter: null,
        displayAcademicPeriod: false,
        students: [],
        selectedStudent: null,
        headersStudents: [],
        searchStudent: '',
        loadingStudents: false,
        activeFormLevel: '',
        listGroup: false,
        formLevels: [],
        selectedFormLevel: null,
    }),


    methods:{
        ...mapMutations({
            //
        }),

        ...mapActions({
            getFormClassesList: 'termReports/getFormClassesList',
            getMarkSheetTerms: 'printViewReports/getMarkSheetTerms',
            getReportCardTerms: 'printViewReports/getReportCardTerms',
            getStudents: 'common/getCurrentStudents',
            getClassListYears: 'printViewReports/getClassListYears',
            downloadASR: 'printViewReports/downloadASR',
        }),

        initialize () {
            this.overlay = true;
            this.loading = true;
            this.mapFormClasses();
            this.loading = false;
            this.overlay = false;
        },

        async mapFormClasses () {
            try {
                const { data } = await this.getFormClassesList();
                if(data.length > 0){                    
                    let form1Classes = [];
                    let form2Classes = [];
                    let form3Classes = [];
                    let form4Classes = [];
                    let form5Classes = [];
                    let form6Classes = []; 
                    data.forEach(record => {
                        switch(record.form_level){
                            case "1":
                            form1Classes.push({
                                title: record.id
                            });
                            break;
                            case "2":
                            form2Classes.push({
                                title: record.id
                            });
                            break;
                            case "3":
                            form3Classes.push({
                                title: record.id
                            });
                            break;
                            case "4":
                            form4Classes.push({
                                title: record.id
                            });
                            break;
                            case "5":
                            form5Classes.push({
                                title: record.id
                            });
                            break;
                            case "6":
                            form6Classes.push({
                                title: record.id
                            });
                            break;
                        }                
                    });
                    this.formClasses.push({
                        title: 'Form 1',
                        action: 'mdi-school',                
                        items: form1Classes,
                        formLevel: 1
                    });
                    this.formClasses.push({
                        title: 'Form 2',
                        action: 'mdi-school',                
                        items: form2Classes,
                        formLevel: 2,
                    });
                    this.formClasses.push({
                        title: 'Form 3',
                        action: 'mdi-school',                
                        items: form3Classes,
                        formLevel: 3,
                    });
                    this.formClasses.push({
                        title: 'Form 4',
                        action: 'mdi-school',                
                        items: form4Classes,
                        formLevel: 4,
                    });
                    this.formClasses.push({
                        title: 'Form 5',
                        action: 'mdi-school',                
                        items: form5Classes,
                        formLevel: 5,
                    });
                    if(form6Classes.length > 0){
                        this.formClasses.push({
                            title: 'Form 6',
                            action: 'mdi-school',                
                            items: form6Classes,
                            formLevel: 6,
                        }); 
                    }
                                                              
                }                
            } catch (error) {
                console.log(error);
            }
        },

        filterFormClasses (formLevel = null) {
            this.listGroup = true;
            this.formClassesFilter = this.formClasses.map(
                value => {
                    value.active = false
                    return value;
                }
            );
            if(formLevel){
                this.formClassesFilter = this.formClasses.filter(value => {
                    if(value.formLevel == formLevel){
                        value.active = true;
                        return value;
                    }
                    
                })
            }            
        },

        setFormLevels () {
            this.listGroup = false;            
            if(this.formLevels.length == 0){                
                this.formClasses.forEach(value => {
                    this.formLevels.push({
                        icon: 'mdi-school',
                        text: 'Form ' + value.formLevel,
                        value: value.formLevel
                    })
                })
            }
        },

        displayReport(report){
            switch(report){
                case 'report-card-class':
                    this.filterFormClasses();
                    this.displayAcademicPeriod = true;
                    this.overlay = true;
                    this.loading = true; 
                    this.reportSelected = report;                  
                    this.setReportCardTerms();                    
                    break
                case 'marksheet-class':
                    this.filterFormClasses();
                    this.displayAcademicPeriod = true;
                    this.overlay = true;
                    this.loading = true; 
                    this.markSheetMenu();                   
                    this.reportSelected = report;                                  
                    break
                case 'class-list':
                    this.filterFormClasses();
                    this.setClassListYears();
                    this.displayAcademicPeriod = true;
                    this.reportSelected = report;
                    this.overlay = true;
                    this.expand= true;
                    break
                case 'marksheet-subject-choice-class':
                    this.displayAcademicPeriod = false;                    
                    this.filterFormClasses(3);
                    this.reportSelected = report;
                    this.overlay = true;
                    this.expand = true;
                    break;
                case 'marksheet-subject-choice-student':
                    this.reportSelected = report;
                    this.loadingStudents = true;
                    this.formLevelFilter = 3;
                    this.setStudents();
                    this.overlay = true;
                    this.expandSelectStudent = true;
                    break;
                case 'registration-status':
                    this.reportSelected = report;
                    this.overlay = true;
                    this.reportViewer = true;
                    this.reportViewerOverlay = true;
                    this.setReportSrc(report);
                    break;
                case 'contact-information':
                    this.filterFormClasses();
                    this.displayAcademicPeriod = false;
                    this.reportSelected = report;
                    this.overlay = true;
                    this.expand= true;
                    break;
                 case 'school-feeding':
                    this.reportSelected = report;
                    this.overlay = true;
                    this.reportViewer = true;
                    this.reportViewerOverlay = true;
                    this.setReportSrc(report);
                    break; 
                case 'device-internet':
                    this.setFormLevels();
                    this.reportSelected = report;
                    this.overlay = true;
                    this.expand = true;                    
                    break;
                case 'asr':
                    this.reportSelected = report;
                    this.overlay = true;
                    this.download();
                    break;
                case 'ethnic-group-statistics':
                    this.reportSelected = report;
                    this.overlay = true;
                    this.reportViewer = true;
                    this.reportViewerOverlay = true;
                    this.setReportSrc(report);                  
                    break;                   
                case 'religion-statistics':
                    this.reportSelected = report;
                    this.overlay = true;
                    this.reportViewer = true;
                    this.reportViewerOverlay = true;
                    this.setReportSrc(report);                 
                    break;
                case 'age-statistics':
                    this.reportSelected = report;
                    this.overlay = true;
                    this.reportViewer = true;
                    this.reportViewerOverlay = true;
                    this.setReportSrc(report);                 
                    break;                         
                case 'health-data':
                    this.reportSelected = report;
                    this.overlay = true;
                    this.reportViewer = true;
                    this.reportViewerOverlay = true;
                    this.setReportSrc(report);                 
                    break;                         
                case 'foreign-students':
                    this.reportSelected = report;
                    this.overlay = true;
                    this.reportViewer = true;
                    this.reportViewerOverlay = true;
                    this.setReportSrc(report);                 
                    break;                         
            }
            
        },

        async markSheetMenu(){
            try {
                let response = await this.getMarkSheetTerms();
                console.log(response);                
                let count = 0;
                response.data.forEach((record,index) => {
                    this.academicPeriods.push({
                        text: record.academic_year + " Term " + record.term,
                        value: record.academic_term_id
                    });
                    count = index;
                })
                //console.log(count);
                this.academicPeriod = response.data[count].academic_term_id;
                this.loading = false;
                // console.log(this.academicPeriods);
                this.$nextTick(function(){
                    this.expand= true;
                })
                
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
        },

        async setReportCardTerms () {            
            try {
                const { data } = await this.getReportCardTerms();                
                data.forEach(record => {
                    this.academicPeriods.push({
                        text: record.academic_year + " Term " + record.term,
                        value: record.academic_term_id
                    });                                       
                })
                this.academicPeriod = data[0].academic_term_id;
            } catch (error) {
                if(error.response) console.log(error.reponse);
                else console.log(error);
            }

            this.loading = false;
            this.$nextTick(function(){
                this.expand = true;
            })

        },

        async setClassListYears () {
            try {
                const { data } = await this.getClassListYears();
                data.forEach(value => {
                    this.academicPeriods.push({
                        text: value.start + '-' + value.end,
                        value: value.academic_year_id

                    })
                    this.academicPeriod = value.academic_year_id;
                })
                console.log(this.academicPeriods);
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
        },

        setSelectedClass(formClass){
            console.log(formClass);
            this.reportViewer = true;
            this.reportViewerOverlay = true;
            this.setReportSrc(this.reportSelected, formClass);         
                      
        },

        setSelectedFormLevel (formLevel) {
            this.selectedFormLevel = formLevel;
            this.reportViewer = true;
            this.reportViewerOverlay = true;
            this.setReportSrc(this.reportSelected); 
        },

        setReportSrc (report, formClass = null) {
            this.$nextTick( function () {
                this.$refs.pdf.onload = () => {
                    console.log('report is loaded');
                    this.reportViewerOverlay = false;
                }
            })

            switch(report){
                case 'report-card-class':                    
                    this.src = process.env.VUE_APP_API_URI + "/api/report-card/"+this.academicPeriod+"/"+formClass;
                    console.log(this.src);
                    break;
                case 'marksheet-class':                    
                    this.src = process.env.VUE_APP_API_URI + "/api/mark-sheet/"+this.academicPeriod+"/"+formClass;
                    console.log(this.src);
                    break;
                case 'class-list':                    
                    this.src = process.env.VUE_APP_API_URI + "/api/class-list/"+formClass+"/"+this.academicPeriod;
                    break;
                case 'marksheet-subject-choice-class':
                    this.src = process.env.VUE_APP_API_URI + 
                    "/api/mark-sheet-subject-choice/"+formClass;
                    break; 
                case 'marksheet-subject-choice-student':
                    this.src = process.env.VUE_APP_API_URI + 
                    "/api/mark-sheet-subject-choice/"+formClass+"/"+this.selectedStudent;
                    break;
                case 'registration-status':
                    this.src = process.env.VUE_APP_API_URI + 
                    "/api/registration-status/1";
                    break;
                case 'contact-information':                    
                    this.src = process.env.VUE_APP_API_URI + "/api/student-contact/"+formClass;
                    break;        
                case 'school-feeding':                    
                    this.src = process.env.VUE_APP_API_URI + "/api/school-feeding";
                    break;        
                case 'device-internet':                    
                    this.src = process.env.VUE_APP_API_URI + "/api/device-internet/"+this.selectedFormLevel;
                    break;
                case 'ethnic-group-statistics':
                    this.src = process.env.VUE_APP_API_URI + "/api/ethnic-group-statistics";
                    break;         
                case 'religion-statistics':
                    this.src = process.env.VUE_APP_API_URI + "/api/religious-group-statistics";
                    break;         
                case 'age-statistics':
                    this.src = process.env.VUE_APP_API_URI + "/api/student-age-statistics";
                    break;          
                case 'health-data':
                    this.src = process.env.VUE_APP_API_URI + "/api/student-health";
                    break;          
                case 'foreign-students':
                    this.src = process.env.VUE_APP_API_URI + "/api/foreign-students";
                    break;          
            }  
        },

        close(){
            this.expandSelectStudent = false;
            this.$nextTick(() => {
                this.expand = false;
                this.selectedReport = null;
                setTimeout(() => { this.overlay = false}, 1000);
            })
            
        },

        closeReport(){
            // console.log(this.reportSelected);
            // if(
            //     this.reportSelected === 'registration-status' ||
            //     this.reportSelected === 'school-feeding'
            // ){
            //     this.overlay = false;
            // }
            this.overlay = false;
            console.log('report closing ...');
            this.src = '';
        },

        setSelectedStudent (item) {
            this.selectedStudent = item.student_id;
            this.setSelectedClass(item.form_class_id);
        },

        async setStudents () {
            this.headersStudents = [
                { text: 'ID', value: 'student_id', width: 100 },
                { text: 'Name', value: 'name' },
                { text: 'Form Class', value: 'form_class_id', width: 100 },
                { text: '', value: 'actions', width: 100, sortable: false }
            ];

            try {
                const { data } = await this.getStudents();
                this.students = data.map(value => {
                    value.name = value.first_name + ' ' + value.last_name;
                    return value;
                })
                if(this.formLevelFilter){
                    this.students = data.filter(value => {
                        if(value.form_level == this.formLevelFilter)
                        value.name = value.first_name + ' ' + value.last_name;
                        return value;
                    })
                }
                this.loadingStudents = false;                
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
        },

        async download () {
            this.loading = true;
            try {
                const { data} = await this.downloadASR();
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(
                    new Blob([data])
                );
                link.setAttribute('download', '2021-2022 Secondary Schools ASR ver. 2 UPDATED.xlsx');
                document.body.appendChild(link);
                link.click();
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
            this.loading = false;
            this.overlay = false;
        },  
    },
   
    
}
</script>