<template>
    <v-card 
        ref="form"
        style="overflow:hidden"
    >
        <v-card-title style="position:relative">
            <span class="headline">{{ formTitle }}</span>
            <v-btn
                fab
                text
                small
                absolute
                right
                top
                class="mr-12"
                @click="registrationForm"
            >
                <v-icon>mdi-printer</v-icon>
            </v-btn>
            <v-btn
                color="red"
                absolute
                top
                right
                text
                fab
                small
                @click="close"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-row 
            justify="center"
            class="mx-0 mb-4"
        >
           
            <v-card
                flat 
                width="250"
                class="px-3 d-flex align-center flex-column"  
            >
                <v-row>
                    <v-col>
                        <v-card
                            v-if="studentRecord.picture"
                            outlined
                            class="mx-auto d-flex justify-center align-center"
                            max-width="200"
                        >
                            <v-img
                                :src="studentRecord.picture"
                            >
                                <template v-slot:placeholder>
                                    <v-row
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center"
                                    >
                                        <v-progress-circular
                                            indeterminate
                                            color="primary"
                                        ></v-progress-circular>
                                    </v-row>
                                </template>
                            </v-img>
                        </v-card>

                        <v-card
                            v-else
                            outlined
                            class="mx-auto d-flex justify-center align-center"
                            width="200"
                            height="200"
                        >
                            <v-card-text
                                class="my-auto" style="text-align:center"
                            >
                                No Picture
                            </v-card-text>
                        </v-card>
                    </v-col>    
                </v-row>    

                <v-row v-if="editPicture">
                    <v-col class="pb-0">
                        <v-file-input
                            :value="pictureFile"
                            text
                            dense
                            outlined
                            @change="uploadPicture"
                            placeholder="Add/Change Picture"
                            prepend-icon="mdi-camera"
                            hide-details
                        >
                        </v-file-input>
                    </v-col>
                </v-row>

                <v-row v-if="pictureFile">
                    <v-col>
                        <v-card
                            class="mx-auto d-flex justify-center align-center"
                            width="200"
                        >
                            <v-progress-linear
                                v-model="progress"
                                color="blue-grey"
                                height="25"
                                
                                reactive
                            >
                                <strong>{{ progress }} %</strong>
                            </v-progress-linear>
                        </v-card>
                    </v-col>
                </v-row>
                
                <v-alert v-if="pictureUploadMessage" border="left" color="primary" dark>
                    {{ pictureUploadMessage}}
                </v-alert>       

                <v-row>
                    <v-col class="px-10">
                        <v-text-field
                            v-model="studentId"
                            label="Student ID"                            
                            readonly
                            outlined                            
                            dense
                            class="mt-4 record-summary"                            
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-card>
            
            <v-card
                width="500"
                flat                
                tile
                style="border-left: thin solid rgba(0, 0, 0, 0.12)"
                class=""                                    
            >
                <v-tabs
                    v-model="tab"
                    fixed-tabs
                >
                    
                    <v-tab
                        v-for="(item, i) in tabItems"
                        :key="i"
                        :disabled="item.disabled"                        
                    >
                        {{ item.section }}
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                    <data-general
                        v-bind:formClasses="formClasses"
                        v-on:update-students="updateStudents"
                        v-on:enable-tabs="enableTabs"
                        ref="dataGeneral"
                    ></data-general>        
                    <data-personal
                        v-bind:record="dataPersonal"
                        v-bind:ethnicGroups="ethnicGroups"
                        v-bind:religiousGroups="religiousGroups"
                    ></data-personal>
                    <data-medical
                        v-bind:record="dataMedical"
                    ></data-medical>
                    <data-family
                        v-bind:records="dataFamily"
                    ></data-family>
                </v-tabs-items>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        outlined
                        v-if="!studentRecord.id"
                        @click="addNewStudent"
                    >
                        Add New Student
                    </v-btn>
                </v-card-actions>    
                
            </v-card>
              
        </v-row>        
        
        <v-overlay
            :value="overlay"
            opacity="0.8"
            z-index="10"
            absolute                    
        >
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>                   
        </v-overlay>

        <v-dialog
            v-model="reportViewer"
            max-width="90vw"
            @click:outside="closeReport"
        >
            <v-card
               height="90vh"
               style="position:relative;" 
            >                
                <iframe 
                    ref="pdf" 
                    style="width:100%; height:100%" 
                    class="report-viewer"  
                    :src="src"
                ></iframe>              
                <v-overlay
                    absolute
                    :value="reportViewerOverlay"
                    color="white"
                    opacity="0.60"
                >
                    <v-progress-circular
                        indeterminate
                        size="64"
                        color="primary"
                    ></v-progress-circular>
                </v-overlay> 
            </v-card>
        </v-dialog> 
    </v-card>  
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DataGeneral from './StudentsEditDataGeneral.vue';
import DataPersonal from './StudentsEditDataPersonal.vue';
import DataMedical from './studentsEditDataMedical.vue';
import DataFamily from './studentsEditDataFamily.vue';
import UploadService from "../services/UploadFileService";

export default {
    components: {
        DataGeneral,
        DataPersonal,
        DataMedical,
        DataFamily,
    },

    props:{
        formTitle: {
            type: String,
            default: '',
        },
        
        formClasses: {
            type: Array,
            default: function () {
                return []
            }
        }
    },   

    created: function () {
        this.initialize();
    },

    computed: {
        ...mapGetters({
            studentRecord: 'students/getEditedStudent'
        })
    },

    data: () => ({
        tab: null,
        tabItems: [
            { section: 'General Info', disabled: false},
            { section: 'Personal Info', disabled: false},  
            { section: 'Medical Info', disabled: false}, 
            { section:'Family Info', disabled: false}
        ],
        errorMessage: '',
        saved: false,
        error: false,
        overlay: false,        
        dataPersonal: {},
        dataMedical: {},
        dataFamily: {},
        religiousGroups: [],
        ethnicGroups: [],
        reportViewer: false,
        reportViewerOverlay: false,
        src: '',
        picture: false,
        pictureSrc: '',
        pictureFile: null,
        pictureUploadMessage: null,
        progress: 100,
        lazySrc: require('../assets/icons/student.svg'),
        editPicture: false,
        studentId: null,
    }),    

    methods: {
        ...mapActions({
            getDataPersonal: 'students/getDataPersonal',
            getDataMedical: 'students/getDataMedical',
            getDataFamily: 'students/getDataFamily',
            getReligiousGroups: 'students/getReligiousGroups',
            getEthnicGroups: 'students/getEthnicGroups',
            postStudent: 'students/postStudent',
        }),

        async initialize () {
            if(!this.studentRecord.student_id){
                this.tabItems.forEach(item => {
                    if(item.section != 'General Info') item.disabled = true;
                })
            }
            else{
                this.studentId = this.studentRecord.student_id;
            }
            this.getData();
        },

        enableTabs () {
            this.tabItems.forEach(item => {
                item.disabled = false;
            })
            this.overlay = false;
        },

        async getData () {
            const promiseDataPersonal = this.getDataPersonal();
            const promiseDataMedical = this.getDataMedical();
            const promiseDataFamily = this.getDataFamily();
            const promiseReligiousGroups = this.getReligiousGroups();
            const promiseEthnicGroups = this.getEthnicGroups();

            try {
                const [
                    { data: dataPersonal },
                    { data: dataMedical },
                    { data: dataFamily }, 
                    { data: religiousGroups }, 
                    {data: ethnicGroups}
                ] = await Promise.all([
                    promiseDataPersonal,
                    promiseDataMedical,
                    promiseDataFamily,
                    promiseReligiousGroups,
                    promiseEthnicGroups
                ])

                this.dataPersonal = dataPersonal;
                // this.loadPicture(dataPersonal);
                this.dataMedical = dataMedical;
                this.dataFamily = Array.isArray(dataFamily) ? {} : dataFamily;

                this.religiousGroups = religiousGroups.map(group => {
                    return {
                        id: group.id,
                        title: group.grouping
                    }
                })

                this.ethnicGroups = ethnicGroups.map(group => {
                    return {
                        id: group.id,
                        title: group.grouping
                    }
                })
                
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
            
            
            
        },

        addNewStudent () {
            console.log('add new student');
            this.overlay = true;
            // console.log(`Validate ${this.$refs.dataGeneral.validate()}`)
            if(!this.$refs.dataGeneral.validate()) this.overlay = false;
            else{
                this.$refs.dataGeneral.update();
            }
        },

        close () {
            this.$emit("close");
            this.tab = null;
            this.error = false;
            this.saved = false;
            this.date = null;
            // Object.keys(this.studentRecord).forEach(f => {
            //     if(f === 'first_name' || f === 'last_name' || f === 'form_class_id'){
            //         this.$refs[f].reset()
            //     }                
            // })
        },
        
        updateStudents (student_id) {
            this.studentId = student_id;
            this.overlay = false;
            this.$emit('update-students');
        },

        registrationForm () {
            this.reportViewer = true;
            this.reportViewerOverlay = true;
            this.$nextTick( function () {
                //console.log(this.$refs.pdf);
                this.$refs.pdf.onload = () => {
                    console.log('report is loaded');
                    this.reportViewerOverlay = false;
                }
            })
            this.src = process.env.VUE_APP_API_URI + "/api/registration-form/"
            + this.studentRecord.student_id;
        },

        closeReport () {
            this.src = null;
        },        

        async uploadPicture (file) {
            this.progress = 0;
            this.pictureFile = file;
            console.log(this.pictureFile);
            if(!this.pictureFile){
                // this.pictureUploadMessage = "Please select a file!";
                return;
            }
            this.pictureUploadMessage = null;

            try {
                const { data } = await UploadService.upload(
                this.pictureFile, 
                this.studentRecord.student_id,
                'picture',
                (event) => {
                    this.progress = Math.round((100 * event.loaded) / event.total);
                    } 
                )
                this.studentRecord.picture = data.picture;
                this.$emit('update-students');
                // console.log(data);
                
            } catch (error) {
               if(error.response) console.log(error.response);
               else console.log(error); 
            }
            this.pictureFile = null;
            
        }
    }
}
</script>

<style scoped>
    ::v-deep .v-tab{
        max-width: 120px;
    }

    ::v-deep .v-slide-group__prev{
        display: none;
    }

    ::v-deep .v-btn--fab.v-size--small.v-btn--absolute.v-btn--top{
        top: 4px;
        right: 4px;
    }

    ::v-deep .record-summary input{
        font-weight: bold;
    }

    ::v-deep .theme--light.v-file-input .v-file-input__text--placeholder{
        font-size: 0.8rem;
    }
</style>